<template>
  <load-quote-modal :active.sync="isActive"
    @close="closeModal()"
    @previous="previous()"
    @next="next()"
    @load="loadMessage()"
    :show-icon="false"
    :previous-disabled="currentStep === steps.CreateAssign.id"
    :next-disabled="currentStep === lastStep.id"
    :continue-disabled="!enableContinue">
    <p slot="text-title">Convert to Quote</p>
    <div slot="text-content">
      <load-quote-steps v-model="loadOptions"
        :current-step="currentStep"
        :steps="steps"
        :ecom-type="eComType"
        :enable-ecom-type-selection="enabledComTypeSelection" />
    </div>
  </load-quote-modal>
</template>

<script>
import { LoadQuoteModal, LoadQuoteSteps, Steps } from './components/LoadQuoteSteps'
import { MessageTypes } from '@/enums'
import Guid from '@/components/Guid'
import BookingService from './BookingService'
import BookingConvertToQuoteValidationSimple from './BookingConvertToQuoteValidationSimple'
import _cloneDeep from 'lodash.clonedeep'
import QuoteRoutes from '@/views/quote/route-types'
import { CustomerOptionTypes } from '@/components/LoadQuoteSteps'

export default {
  name: 'BookingConvertToQuoteSimple',
  inject: ['$vv'],
  components: {
    LoadQuoteModal,
    LoadQuoteSteps
  },
  mixins: [BookingConvertToQuoteValidationSimple],
  props: {
    value: null,
    quoteBookingId: null,
    entity: null,
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerEntity: this.entity,
      isActive: this.active || false,
      selectedRow: null,
      isLoadQuoteModalActive: false,
      steps: Steps,
      currentStep: Steps.CreateAssign.id,
      loadOptions: this.value
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    lastStep() {
      return this.steps.CreateAssign
    },
    enableContinue() {
      return this.lastStep.id === this.currentStep
    },
    eComType() {
      if (this.entity.isRepx) return 'ORM'
      // return 'all'
      return 'ORM' //suppose pass from booking detail page
    },
    enabledComTypeSelection() {
      if (!this.entity.isRepx)  return true
      return false
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    loadOptions: {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        //console.log(this.$v)
        this.$emit('input', newVal)
      },
      deep: true
    },
    entity: {
      handler: function (newVal, oldVal) {
        this.innerEntity = newVal
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    //console.log(this.value)
    // console.log(this.$v)
  },
  mounted() {},
  methods: {
    previous() {
      this.currentStep--
      if (this.currentStep === this.steps.CreateAssign.id) {
        this.$v.loadOptions.quoteId.$reset()
      } else if (this.currentStep === this.steps.SelectInsurer.id) {
        this.$v.loadOptions.insurerId.$reset()
      } else if (this.currentStep === this.steps.UpdateCustomer.id) {
        this.$v.loadOptions.customerId.$reset()
      }
    },
    next() {
      switch (this.currentStep) {
        case Steps.CreateAssign.id:
          this.$v.loadOptions.quoteId.$touch()
          if (!this.$v.loadOptions.quoteId.$error || this.loadOptions.isCreateQuote) {
            this.currentStep++
          }
          break
      }
    },
    reset() {
      this.loadOptions.messageId = Guid.empty()
      this.loadOptions.messageTypeId = MessageTypes.QML_RequestForQuote
      this.loadOptions.quoteId = Guid.empty()
      this.loadOptions.isCreateQuote = true
      this.loadOptions.insurerId = Guid.empty()
      this.loadOptions.customerOption = CustomerOptionTypes.Create
      this.loadOptions.isOverwriteVehicle = false
      this.loadOptions.customerId = Guid.empty()
      this.loadOptions.title = ''
      this.loadOptions.customerName = ''
      this.loadOptions.firstname = ''
      this.loadOptions.lastname = ''
      this.loadOptions.isPerson = false
      this.loadOptions.regoNo = ''
      this.loadOptions.vehicleId = Guid.empty()
      this.loadOptions.isOverwriteOrmMessageNo = false
      this.currentStep = Steps.CreateAssign.id
      this.selectedMessage = null
      this.isRepx = null
      // Reset validation objects
      this.$v.loadOptions.$reset()
    },
    closeModal() {
      this.$emit('close')
      this.reset()
    },

    async loadMessage() {
      this.isActive = false
      this.$emit('close')
      this.$showSpinner('Loading quote')
      try {
        this.$showSpinner('Converting...')
        console.log(this.innerEntity)

        var _entity = _cloneDeep(this.entity)
        _entity.loadOptions = _cloneDeep(this.loadOptions)

        var quote = await BookingService.putConvertBookingToQuote(_entity)
        var route = QuoteRoutes.QuoteView.path.replace(':quoteId', quote.data.quoteId)
        this.$router.push(`${route}`)
        // this.$router.push({
        //   name: 'VehicleListView',
        //   params: { id: quoteId }
        // })
        this.$hideSpinner()
        this.$emit('close')
        // this.isLoadQuoteModalActive = false
        // this.isOrmMessageNoModalActive = false
        // const result = await OrmMessageService.loadMessage(this.loadOptions)
        // this.quoteId = result.data
        // if (Guid.validGuid(this.quoteId)) {
        //   this.quoteNo = await OrmMessageService.getQuoteNo(this.quoteId)
        //   this.print(this.loadOptions.messageId, this.loadOptions.messageTypeId)
        //   if (this.loadOptions.isCreateQuote && this.loadOptions.messageTypeId === MessageTypes.QML_RequestForQuote) {
        //     this.createAuditTrail(this.quoteId, 'insert', '')
        //   } else {
        //     // TODO: create update AuditTrail entry
        //   }
        //   this.savedLoadOptions = _cloneDeep(this.loadOptions)
        //   this.isConfirmModalActive = true
        //}
      } catch (e) {
        throw e
      } finally {
        this.reset()
        this.$hideSpinner()
      }
    }
  }
}
</script>
